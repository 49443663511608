import * as act from './actions'

export const initialState: object = {}

export function reducer(state = initialState, action: act.Actions): object {
  switch (action.type) {
    case act.REPORT_PLAYER_ACTIVITY:
    case act.REPORT_LIMIT_ACTIVITY:
    case act.REPORT_FEE_ACTIVITY:
    case act.REPORT_PAYMENT_ACTIVITY:
    case act.REPORT_SCORING_FEES:
    case act.REPORT_PROGRAM_FEES:
    case act.REPORT_CWA_ENABLE_DISABLE:
    case act.REPORT_LIMIT_ADJUSTMENT:
    case act.REPORT_FEE:
    case act.REPORT_MT_SUMMARY:
    case act.REPORT_UNDERWRITING:
    case act.REPORT_CWA_DETAIL:
    case act.REPORT_CWA_ADJUSTMENT:
    case act.REPORT_PATRON_CWA_DETAILS:
    case act.REPORT_PROGRAM_MANAGEMENT_FEES:
      return {
        ...state,
        loading: true,
      }
    case act.REPORT_PLAYER_ACTIVITY_SUCCESSFUL:
    case act.REPORT_LIMIT_ACTIVITY_SUCCESSFUL:
    case act.REPORT_FEE_ACTIVITY_SUCCESSFUL:
    case act.REPORT_PAYMENT_ACTIVITY_SUCCESSFUL:
    case act.REPORT_SCORING_FEES_SUCCESSFUL:
    case act.REPORT_PROGRAM_FEES_SUCCESSFUL:
    case act.REPORT_CWA_ENABLE_DISABLE_SUCCESSFUL:
    case act.REPORT_LIMIT_ADJUSTMENT_SUCCESSFUL:
    case act.REPORT_FEE_SUCCESSFUL:
    case act.REPORT_MT_SUMMARY_SUCCESSFUL:
    case act.REPORT_UNDERWRITING_SUCCESSFUL:
    case act.REPORT_CWA_DETAIL_SUCCESSFUL:
    case act.REPORT_CWA_ADJUSTMENT_SUCCESSFUL:
    case act.REPORT_PATRON_CWA_DETAILS_SUCCESSFUL:
    case act.REPORT_PROGRAM_MANAGEMENT_FEES_SUCCESSFUL:
      return {
        ...state,
        ...action.data,
        loading: false,
        authorized: true,
      }
    case act.REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_FEE_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_PAYMENT_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_SCORING_FEES_UNSUCCESSFUL:
    case act.REPORT_PROGRAM_FEES_UNSUCCESSFUL:
    case act.REPORT_CWA_ENABLE_DISABLE_UNSUCCESSFUL:
    case act.REPORT_LIMIT_ADJUSTMENT_UNSUCCESSFUL:
    case act.REPORT_FEE_UNSUCCESSFUL:
    case act.REPORT_MT_SUMMARY_UNSUCCESSFUL:
    case act.REPORT_UNDERWRITING_UNSUCCESSFUL:
    case act.REPORT_CWA_DETAIL_UNSUCCESSFUL:
    case act.REPORT_CWA_ADJUSTMENT_UNSUCCESSFUL:
    case act.REPORT_PATRON_CWA_DETAILS_UNSUCCESSFUL:
    case act.REPORT_PROGRAM_MANAGEMENT_FEES_UNSUCCESSFUL:
      return {
        ...state,
        loading: false,
        authorized: false,
      }
    default:
      return state
  }
}

export const getReportPlayerActivity = (state: any) => state
export const getReportLimitActivity = (state: any) => state
export const getReportPaymentActivity = (state: any) => state
export const getReportFeeActivity = (state: any) => state
export const getReportScoringFee = (state: any) => state
export const getReportProgramFee = (state: any) => state
export const getReportCWAEnableDisable = (state: any) => state
export const getReportLimitAdjustment = (state: any) => state
export const getReportFee = (state: any) => state
export const getReportMTSummary = (state: any) => state
export const getUnderwritingReport = (state: any) => state
export const getReportCwaDetail = (state: any) => state
export const getReportCwaAdjustment = (state: any) => state
export const getReportPatronCwaDetails = (state: any) => state
export const getProgManagementFeesReport = (state: any) => state
