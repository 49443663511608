<div class="footer">
  <div class="footerInner">
    <a href="#" target="_blank" rel="noopener noreferrer" class="logo">
      Marker Trax
    </a>
    <br />
    <p class="mb-0">
      Copyright © 2021 Marker Trax |
      <!-- <a href="https://mt-legal.s3-us-west-2.amazonaws.com/ellis_island/ELLIS+ISLAND+CASINO+PRIVACY+POLICY.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a> -->
      <a href="#" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </p>
  </div>
</div>