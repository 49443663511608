import { ConfigService } from './../config.service'
import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'

@Injectable()
export class jwtAuthService {
  constructor(
    private http: CustomHttpService,
    private router: Router,
    public config: ConfigService,
  ) {}

  login(EmailAddress: string, Password: string): Observable<any> {
    return this.http.post(
      `Identity/Login`,
      { Username: EmailAddress, Password: Password },
      {
        headers: { 'Content-Type': 'application/json' },
        // responseType: 'text' as 'json',
      },
    )
  }

  register(email: string, password: string, name: string): Observable<any> {
    return this.http.post('/api/auth/register', { email, password, name })
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post('Identity/ForgotPassword', { Username: email })
  }

  setPassword(params): Observable<any> {
    if (params.id) {
      // from email request
      return this.http.put('Identity/SetPasswordByLinkedId', {
        LinkId: params.id,
        Password: params.password,
      })
    } else {
      // from force change pw
      return this.http.put('Identity/SetNewPassword', {
        NewPassword: params.password,
      })
    }
  }

  currentAccount(): Observable<any> {
    return this.http.get(`Identity/GetUserInformationBySessionId`)
  }

  logout(): Observable<any> {
    return this.http.post(`Identity/Logout`, {})
  }
  getLoginSettings(): Observable<any> {
    return this.http.get('Identity/ListLoginSetting')
  }
  setIPAddress(data): Observable<any> {
    return this.http.post('Identity/SendIPAddressVerificationCodeV2', data)
  }
  validateIPAddress(data): Observable<any> {
    return this.http.post('Identity/ConfirmVerificationCodeV2', data)
  }
}
