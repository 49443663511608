import * as act from './actions'

export const initialState: object = {}

export function reducer(state = initialState, action: act.Actions): object {
  switch (action.type) {
    case act.ROLE_LIST:
    case act.USER_LIST:
    case act.GROUP_LIST:
    case act.ADD_USER:
    case act.DELETE_USER:
    case act.UPDATE_USER:
    case act.UPDATE_GROUP:
    case act.DELETE_GROUP:
    case act.MODULE_LIST:
    case act.ADD_ROLE:
    case act.UPDATE_ROLE:
    case act.DELETE_ROLE:
    case act.ADD_PERMISSION:
    case act.OPS_RESET_PWD:
      return {
        ...state,
        loading: true,
      }
    case act.ROLE_LIST_SUCCESSFUL:
    case act.USER_LIST_SUCCESSFUL:
    case act.GROUP_LIST_SUCCESSFUL:
    case act.ADD_USER_SUCCESSFUL:
    case act.DELETE_USER_SUCCESSFUL:
    case act.UPDATE_USER_SUCCESSFUL:
    case act.UPDATE_GROUP_SUCCESSFUL:
    case act.DELETE_GROUP_SUCCESSFUL:
    case act.MODULE_LIST_SUCCESSFUL:
    case act.ADD_ROLE_SUCCESSFUL:
    case act.UPDATE_ROLE_SUCCESSFUL:
    case act.DELETE_ROLE_SUCCESSFUL:
    case act.ADD_PERMISSION_SUCCESSFUL:
    case act.OPS_RESET_PWD_SUCCESSFUL:
      return {
        ...state,
        ...action.data,
        loading: false,
        authorized: true,
      }
    case act.USER_LIST_UNSUCCESSFUL:
    case act.USER_LIST_UNSUCCESSFUL:
    case act.GROUP_LIST_UNSUCCESSFUL:
    case act.ADD_USER_UNSUCCESSFUL:
    case act.DELETE_USER_UNSUCCESSFUL:
    case act.UPDATE_USER_UNSUCCESSFUL:
    case act.UPDATE_GROUP_UNSUCCESSFUL:
    case act.DELETE_GROUP_UNSUCCESSFUL:
    case act.MODULE_LIST_UNSUCCESSFUL:
    case act.ADD_ROLE_UNSUCCESSFUL:
    case act.UPDATE_ROLE_UNSUCCESSFUL:
    case act.DELETE_ROLE_UNSUCCESSFUL:
    case act.ADD_PERMISSION_UNSUCCESSFUL:
    case act.OPS_RESET_PWD_UNSUCCESSFUL:
      return {
        ...state,
        loading: false,
        authorized: false,
      }
    default:
      return state
  }
}

export const getUserList = (state: any) => state
export const getRoleList = (state: any) => state
export const getGroupList = (state: any) => state
export const getModuleList = (state: any) => state
export const opsResetPwdResult = (state: any) => state
export const addUserResult = (state: any) => state
